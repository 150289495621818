import React from 'react'
import { Link } from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                    IT Staffing Services
                                </Link>
                            </h3>

                            <p>At KommForce Solutions, we bring our clients the best of the industry has to offer. That’s why, we develop strong relationships with clients that complement and enhance KommForce solutions, Staffing services, and technology.</p>

                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
                            <h3>
                                <Link to="#">
                                    Web Automation
                                </Link>
                            </h3>
                            <p>Speed up the creation, execution, and maintenance of your automated testing, CICD, Regression Run your cross-browser tests where you want them, on our grid or any Selenium-compatible grid.</p>

                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                    API Automation
                                </Link>
                            </h3>

                            <p>KommForce Team brings a hassle-free API testing automation platform for customers to speed up catching issues, CICD intergration, Regression runs to avoid bugs at api level of testing than UI level.</p>

                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                    Mobile Automation
                                </Link>
                            </h3>

                            <p>Our Appium, Selenium based automation services make customers quickly run their test cases with speed and accuracy to maintain high quality in application deliverables, we cover both IOS and Android.</p>

                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                    Accessibility Automation
                                </Link>
                            </h3>

                            <p>Most accessibility tests today are done manually — or not at all. Accessibility testing is not part of the lifecycle, and it usually isn’t automated. But it should be, our automation framworks and tooling solutions greatly help find bugs quickly.</p>.

                            {/* <p>Accessibility needs to be embedded right from the beginning of development. Like other types of testing, by shifting left, you can identify accessibility defects when they’re less expensive to fix. Once an accessibility defect is in production, the cost to fix it can be 100x higher than when it’s addressed in design.</p> */}

                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                    Performance Automation
                                </Link>
                            </h3>

                            <p>Our wide variety of performance software testing frameworks/tools used to test applications, measuring system behavior, and performance under load. It can simulate thousands of users concurrently using application software.</p>

                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne